import React from "react"
import Layout from "../components/layout"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Img from "gatsby-image"

const Recenzije = ({ data }) => {
  const intl = useIntl()
  const slike = data.allFile.edges

  return (
    <Layout>
      <SEO lang={intl.locale} title={intl.formatMessage({ id: "recenzije" })} />
      <div className="section blue-background lokacija">
        <div className="content">
          <div className="card">
            <h1>{intl.formatMessage({ id: "recenzije" })}</h1>
            <div className="recenzije-items">
              {slike.map((slika, i) => (
                <a key={i} href={slika.node.childImageSharp.fluid.src}>
                  <Img
                    key={slika.node.childImageSharp.id}
                    fluid={{
                      ...slika.node.childImageSharp.fluid,
                      sizes: "(min-width: 768px) 400px, 100vw",
                    }}
                    alt="review award image"
                  />
                </a>
              ))}
              <div
                style={{ textAlign: "center" }}
                itemscope
                itemtype="http://schema.org/Organization"
              >
                <span style={{ display: "none" }} itemprop="name">
                  Apartments And Rooms Erik
                </span>
                <div
                  itemprop="aggregateRating"
                  itemscope
                  itemtype="http://schema.org/AggregateRating"
                >
                  <a
                    href="https://www.kayak.com/Rovinj-Hotels-Apartments-And-Rooms-Erik.505727.ksp"
                    target="_blank"
                  >
                    <img
                      style={{ width: "100%", objectFit: "contain" }}
                      src="https://content.r9cdn.net/seo-res/badges/v3/ORANGE_LARGE_TRAVEL_AWARDS.png"
                    />
                  </a>
                  <p
                    style={{
                      color: "black",
                      fontSize: "14px",
                      fontFamily: "Arial, Helvetica, sans-serif",
                      margin: "4px 0",
                    }}
                  >
                    Traveler Rating: <span itemprop="ratingvalue">9.4</span>
                    <meta itemprop="bestRating" content="10"></meta>
                    <meta itemprop="ratingCount" content="306"></meta> Excellent
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Recenzije

export const query = graphql`
  query {
    allFile(
      filter: { relativeDirectory: { regex: "/recenzije/" } }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(sizes: "(min-width: 10px) 500px, 500px", maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
          id
          relativeDirectory
        }
      }
    }
  }
`
